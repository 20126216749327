import federatedRedux from 'federated-redux';

interface RuntimeConfig {
  [key: string]: string | boolean | number;
}

declare global {
  interface Window {
    rootRuntimeConfig: RuntimeConfig,
    managerRuntimeConfig: RuntimeConfig,
    BUILD_DATE: string;
  }
}

// Fetch the runtime config with several attempts
function getRuntimeConfig(attempt = 1): Promise<RuntimeConfig> {
  const maxAttempts = 3;
  const runtimeConfigUrl = '/config.json';

  return fetch(runtimeConfigUrl)
    .then(res => {
      if (res.ok){
        return res.json();
      }

      if (attempt < maxAttempts){
        console.warn('Failed to fetch runtime config, running another attempt...')
        return getRuntimeConfig(attempt + 1);
      }

      console.error('Failed to fetch runtimme configuration!')
      console.error(res.statusText);
      return {};
  });
};

// Alternatively fetch the runtime config from process.env
function getRuntimeConfigFromEnv() {
  return Promise.resolve({
    API_URL: process.env.API_URL,
    API_V3_URL: process.env.API_V3_URL,
    ELEARNING_URL: process.env.ELEARNING_URL,
    LEARNING_PAGE_URL: process.env.LEARNING_PAGE_URL,
    IMAGES_URL: process.env.IMAGES_URL,
    SURVEY_HUB_API_URL: process.env.SURVEY_HUB_API_URL,
    STATUS_PAGE_URL: process.env.STATUS_PAGE_URL,
    // Deployment
    ENVIRONMENT: process.env.ENVIRONMENT,
    DEPLOY_ENVIRONMENT: process.env.DEPLOY_ENVIRONMENT,
    // Amplitude tracking environment variables
    AMPLITUDE_ACTIVATED: process.env.AMPLITUDE_ACTIVATED,
    AMPLITUDE_VERBOSE: process.env.AMPLITUDE_VERBOSE,
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    // Chameleon environment variables
    CHAMELEON_ACTIVE: process.env.CHAMELEON_ACTIVE,
    CHAMELEON_API_KEY: process.env.CHAMELEON_API_KEY,
    CHAMELEON_BLACKLIST: process.env.CHAMELEON_BLACKLIST as string,
    // Sentry
    SENTRY_DSN: process.env.SENTRY_DSN,
    // Admin users
    ENABLE_ADMIN_USERS: process.env.ENABLE_ADMIN_USERS,
    ENABLE_ADMIN_USERS_EDIT: process.env.ENABLE_ADMIN_USERS_EDIT,
  });
}

// Fetch configuration
const runtimeConfigPromise = process.env.USE_RUNTIME_CONFIG === 'true'
  ? getRuntimeConfig()
  : getRuntimeConfigFromEnv();

// Load federated redux dispatcher before app boots
const federatedDispatcherPromise = import('Manager/reduxFederatedDispatch');

Promise.all([runtimeConfigPromise, federatedDispatcherPromise])
  .then(([runtimeConfig, { default: federatedDispatcher }]) => {
    // Set the runtime config for global usage
    window.rootRuntimeConfig = runtimeConfig;

    // Set the federated redux dispatcher for local usage
    federatedRedux.dispatch = federatedDispatcher;

    // Let webpack load federated chunks asynchronously
    import(/* webpackChunkName: "root-bootstrap" */'./bootstrap');
  });

export {};
